<div style="width: 100%; background: #E2E2E2;">
    <div class="container">

        <div class="row" style="min-height: 5vh;"> </div>
        <div class="row">
            <div class="col-sm-4" (click)="navigateToGranite_01();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/ivory-fantasy-granite.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>

                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Ivory Fantasy Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Creamy colors and pinkish tones
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_02();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Kuppam-Green.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color: #E2E2E2;">
                            Kuppam Green
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: #E2E2E2;">
                            Green grey background with more darker and lighter cloudy pattern
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_03();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Granite-classic-ivory.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Classic Ivory Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Has a white background with dapples and veins
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToGranite_04();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Colonial-white.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Colonial White Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            White / ivory patterns, with gold and brown veins
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_05();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Bianco-Regal Stones.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:black">
                            Bianco Regal Stones Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Loved for its crisp white bedrock and stunning brown mineral deposits
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_06();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Imperial-White-granite.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color:black">
                            Imperial White Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Renowned for its beauty
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToGranite_07();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/bash-paradiso.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>

                        <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Bash Paradiso Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Paradiso Bash granite is a natural stone from Krishnagiri District
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_08();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/KASHMIR-WHITE-Granite.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color: black;">
                            Kashmir White Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Its overall appearance is generally a very light grey
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_09();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/River-White.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText">
                            River White Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Indian Granite originating out of Visakhapatnam
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToGranite_10();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Tiger-Skin.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Tiger Skin Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Has brown, orange and red background with black waves
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_11();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Black-Galaxy.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:#E2E2E2">
                            Black Galaxy Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:#E2E2E2">
                            Has pitch dark black background with golden and silver speckles
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_12();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Blue-Galaxy-White.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color:black">
                            Blue Galaxy White Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            White or cream base with brown and blue flowers, and specs ans swirls of blue and grey
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToGranite_13();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Black-Pearl.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Black Pearl Granite 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Originating out of Chilikr District.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_14();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Red-multicolor.png')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:#E2E2E2">
                            Red Multicolor Granite 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:#E2E2E2">
                            blend of different shades where red dominates the other colors
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_15();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Classic-Paradiso.png')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color:black">
                            Classic Paradiso Granite 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Comprises of delicate array of multi-coloured minerals.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToGranite_16();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Sapphire-blue.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Sapphire Blue Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Has brown, orange and red background with black waves
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_17();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/black-forest-granite.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:#E2E2E2">
                            Black Forest Granite 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:#E2E2E2">
                            Dark background with white veins
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToGranite_18();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Blue-dunes.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color:black">
                            Blue Dunes Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Blue and green background and brown streaks.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>
    </div>
</div>