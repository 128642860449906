<div style="width: 100%; background: rgba(225, 227, 228, 0);">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="centerAlign">
                    <div class="marginLowLow"></div>
                    <div class="fontForTitle">
                        Our Top Products
                    </div>
                    <div class="margin"></div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-sm-4">

                <div class="setBackGround" style="background-image: url('/assets/images/granite.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        
                        <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Quality description of granite.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4">

                <div class="setBackGround" style="background-image: url('/assets/images/marble.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                                         <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color: black;">
                            Marble
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Quality description of marble
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4">

                <div class="setBackGround" style="background-image: url('/assets/images/limestone.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                                           <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Limestone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Quality Description of limestone
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" >

                <div class="setBackGround" style="background-image: url('/assets/images/quartzite.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                                     <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Quartzite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Quality description of quartzite
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" >

                <div class="setBackGround" style="background-image: url('/assets/images/sandstone.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                                           <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText">
                            Sandstone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Quality description of sandstone
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" >

                <div class="setBackGround" style="background-image: url('/assets/images/slate.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                                         <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Slate
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Quality description of slate
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>
    </div>
</div>