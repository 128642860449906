import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component'
import { PopulateDescriptionComponent } from './populate-description/populate-description.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { GraniteComponent } from './granite/granite.component';
import { MarbleComponent } from './marble/marble.component';
import { OthersPageComponent } from './others-page/others-page.component'
import { MonumentsComponent } from './monuments/monuments.component';
import { QuartziteComponent } from './quartzite/quartzite.component';
import { SlateComponent } from './slate/slate.component';
import { SandstoneComponent } from './sandstone/sandstone.component';
import { LimestoneComponent } from './limestone/limestone.component';


const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'description/:id', component: PopulateDescriptionComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'granite', component: GraniteComponent },
  { path: 'marble', component: MarbleComponent },
  { path: 'others', component: OthersPageComponent },
  { path: 'monuments', component: MonumentsComponent },
  { path: 'quartzite', component: QuartziteComponent },
  { path: 'slate', component: SlateComponent },
  { path: 'sandstone', component: SandstoneComponent },
  { path: 'limestone', component: LimestoneComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
