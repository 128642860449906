<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6">
            <div class="setBackGround">
                <div class="fontForTitle" style="color:#161925">

                    Do It

                </div>

                <div class="multiColor">
                    <div class="fontForTitle" style="color:#161925">
                        Once
                    </div>

                    <div class="fontForTitle" style="color:#f0473e; padding-left: 15px;">
                        . Do It
                    </div>
                </div>

                <div class="fontForTitle" style="color:#f0473e;">
                    With Stone!
                </div>
                <div class="centerAlign">
                    <div class="padding"></div>
                    <div class="fontForSubSubText" style="color:#161925">
                        We deliver the best quality stones from the best quarries in India. Do it once, do it with
                        stone.

                    </div>
                    <div class="paddingHigh"></div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">

        </div>
    </div>
</div>