<app-first-page>

</app-first-page>

<app-second-page>

</app-second-page>

<app-home-page-carousle>
    
</app-home-page-carousle>

<app-third-page>
    
</app-third-page>

<app-forth-page>
    
</app-forth-page>

<app-seventh-page>
    
</app-seventh-page>

<app-sixth-page>

</app-sixth-page>

<!-- <app-eigth-page>

</app-eigth-page>

<app-ninth-page>
    
</app-ninth-page> -->

<app-fifth-page>

</app-fifth-page>

<app-contact-us>
    
</app-contact-us>

<app-last-page>
    
</app-last-page>