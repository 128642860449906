import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slate',
  templateUrl: './slate.component.html',
  styleUrls: ['./slate.component.css']
})
export class SlateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
