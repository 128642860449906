<div style="width: 100%; background: #E2E2E2;">
    <div class="container">

        <div class="row" style="min-height: 5vh;"> </div>
        <div class="row">
            <div class="col-sm-4" (click)="navigateToMarble_01();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/fantasy-brown-marble.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>

                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Fantasy Brown Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Creamy colors and pinkish tones
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToMarble_02();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Verde-Guatemala.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color: #E2E2E2;">
                            Verde Guatemala Granite
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: #E2E2E2;">
                            Green grey background with more darker and lighter cloudy pattern
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToMarble_03();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Rajasthan-Fantasy-Brown.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Rainforest Brown Marble
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Has a white background with dapples and veins
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToMarble_04();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Rajasthan-Fancy-Green.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Rainforest Green Marble
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            White / ivory patterns, with gold and brown veins
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToMarble_05();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Cosmic-Waves.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:black">
                            Cosmic Waves Marble
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Loved for its crisp white bedrock and stunning brown mineral deposits
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>
    </div>
</div>