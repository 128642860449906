import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-limestone-second',
  templateUrl: './limestone-second.component.html',
  styleUrls: ['./limestone-second.component.css']
})
export class LimestoneSecondComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToLimestone_01() {
    this.router.navigateByUrl("/description/limestone-01")
    console.log("this was called ")
  }

  navigateToLimestone_02() {
    this.router.navigateByUrl("/description/limestone-02")
    console.log("this was called ")
  }

  navigateToLimestone_03() {
    this.router.navigateByUrl("/description/limestone-03")
    console.log("this was called ")
  }

  navigateToLimestone_04() {
    this.router.navigateByUrl("/description/limestone-04")
    console.log("this was called ")
  }

  navigateToLimestone_05() {
    this.router.navigateByUrl("/description/limestone-05")
    console.log("this was called ")
  }

  navigateToLimestone_06() {
    this.router.navigateByUrl("/description/limestone-06")
    console.log("this was called ")
  }

}
