<!-- this.urlCrossEngraved = "/assets/images/RGM-006.jpg"
this.urlEuropeanStyleGreen = "/assets/images/RGM-004.jpg"
this.urlUSAStyleAngelEngraved = "/assets/images/RGM-011.jpg"
this.urlSerpentineShaped = "/assets/images/RGM-003.jpg"
this.urlHearthShapedGranite = "/assets/images/RGM-001.jpg"
this.urlDoubleShapedPolished = "/assets/images/RGM-005.jpg" -->

<div style="width: 100%; background: #E2E2E2;">
    <div class="container">

        <div class="row" style="min-height: 5vh;"> </div>
        <div class="row">
            <div class="col-sm-4" (click)="navigateToMonument_01();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/RGM-006.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>

                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Cross-Engraved Cats Eye Granite Monumental Stone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Creamy colors and pinkish tones
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToMonument_02();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/RGM-004.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color: #E2E2E2;">
                            Belgium-style Tropical Green Granite Monument
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: #E2E2E2;">
                            Green grey background with more darker and lighter cloudy pattern
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToMonument_03();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/RGM-011.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Angel Engraved Absolute Black Granite Monument product
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Has a white background with dapples and veins
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToMonument_04();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/RGM-004.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Cats Eye Granite Monument
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            White / ivory patterns, with gold and brown veins
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToMonument_05();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/RGM-003.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:black">
                            Absolute black granite monument product
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Loved for its crisp white bedrock and stunning brown mineral deposits
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToMonument_06();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/RGM-001.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:black">
                            The European-style polished granite monument
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Loved for its crisp white bedrock and stunning brown mineral deposits
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>
    </div>
</div>