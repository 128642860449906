<div class="container-fluid">

    <div class="row" style="height: 15vh;">
        <div class="col-sm-12"></div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <div>
                <div class="setBackGround">
                    <div class="fontSmallSetBoldSecond">
                        Reach out to us!
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>Your Name</mat-label>
                        <input formControlName="country" matInput placeholder="Placeholder">
                        <mat-hint>India</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Email Address</mat-label>
                        <input formControlName="country" matInput placeholder="Placeholder">
                        <mat-hint>India</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Contact Number</mat-label>
                        <input formControlName="country" matInput placeholder="Placeholder">
                        <mat-hint>India</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Your Query</mat-label>
                        <input formControlName="country" matInput placeholder="Placeholder">
                        <mat-hint>India</mat-hint>
                    </mat-form-field>

                    <button mat-raised-button color="accent" type="submit"
                        style="float: right; margin-top: 3vh;">Submit</button>

                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="centerAlign">
                <img src="/assets/images/map.png" style="width: 40vw; float: right">
            </div>
        </div>
    </div>

</div>