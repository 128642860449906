<div class="setBackGroundBlack">
    <div class="centerAlign">

        <div class="fontForTitle" style="margin-top: 3vh; line-height: 30px;">
            Others
        </div>


        <div class="padding"></div>


        <div class="fontForSubSubText">
            Regal Stones was founded to deliver the best quality standards and ensure lasting experiences for all its
            stakeholders.
        </div>

    </div>
</div>

<app-granite-second>
    
</app-granite-second>