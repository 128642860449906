import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-quartzite-second',
  templateUrl: './quartzite-second.component.html',
  styleUrls: ['./quartzite-second.component.css']
})
export class QuartziteSecondComponent implements OnInit {

  constructor(private  router:Router) { }

  ngOnInit(): void {
  }

  navigateToQuart_01() {
    this.router.navigateByUrl("/description/quart-01")
    console.log("this was called ")
  }

  navigateToQuart_02() {
    this.router.navigateByUrl("/description/quart-02")
    console.log("this was called ")
  }

  navigateToQuart_03() {
    this.router.navigateByUrl("/description/quart-03")
    console.log("this was called ")
  }

  navigateToQuart_04() {
    this.router.navigateByUrl("/description/quart-04")
    console.log("this was called ")
  }

  navigateToQuart_05() {
    this.router.navigateByUrl("/description/quart-05")
    console.log("this was called ")
  }

  navigateToQuart_06() {
    this.router.navigateByUrl("/description/quart-06")
    console.log("this was called ")
  }

}
