import { Component, OnInit ,AfterViewInit, ElementRef} from '@angular/core';
declare var Flickity: any;

@Component({
  selector: 'app-fifth-page',
  templateUrl: './fifth-page.component.html',
  styleUrls: ['./fifth-page.component.css']
})
export class FifthPageComponent implements AfterViewInit {

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const el = this.el.nativeElement;

    const elem = el.querySelector('.carousel');
    new Flickity( elem, {
      imagesLoaded: true, 
      percentPosition: false,
      wrapAround: true,
      autoPlay: true
    });
  }

}
