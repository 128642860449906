import { Component, OnInit } from '@angular/core';
import{Router} from '@angular/router';

@Component({
  selector: 'app-slate-second',
  templateUrl: './slate-second.component.html',
  styleUrls: ['./slate-second.component.css']
})
export class SlateSecondComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToSlate_01() {
    this.router.navigateByUrl("/description/slate-01")
    console.log("this was called ")
  }

  navigateToSlate_02() {
    this.router.navigateByUrl("/description/slate-02")
    console.log("this was called ")
  }

  navigateToSlate_03() {
    this.router.navigateByUrl("/description/slate-03")
    console.log("this was called ")
  }

  navigateToSlate_04() {
    this.router.navigateByUrl("/description/slate-04")
    console.log("this was called ")
  }

  navigateToSlate_05() {
    this.router.navigateByUrl("/description/slate-05")
    console.log("this was called ")
  }

  navigateToSlate_06() {
    this.router.navigateByUrl("/description/slate-06")
    console.log("this was called ")
  }

}
