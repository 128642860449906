<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6">
            <div class="setBackGround">
                <div class="fontForTitle" style="color:whitesmoke">

                    The

                </div>

                <div class="multiColor">
                    <div class="fontForTitle" style="color:whitesmoke">
                        widest
                    </div>

                    <div class="fontForTitle" style="color:#f0473e; padding-left: 15px;">
                        of
                    </div>
                </div>

                <div class="fontForTitle" style="color:#f0473e;">
                    varieties!
                </div>
                <div class="centerAlign">
                    <div class="padding"></div>
                    <div class="fontForSubSubText" style="color:whitesmoke">

                        With its comprehensive range of construction stones in an array of eye-pleasing shades, the
                        company offers a big assortment of natural stone products in different shapes, dimensions
                        and
                        finishes that are hard to find anywhere.

                    </div>
                    <div class="paddingHigh"></div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">

        </div>
    </div>
</div>