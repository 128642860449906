import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface ProductDescriptions {
  stoneType: string;
  stoneColor: string;
  location: string;
}

const ELEMENT_DATA: ProductDescriptions[] = [
  { stoneType: "1", stoneColor: 'Stone Type', location: "Granite" },
  { stoneType: "2", stoneColor: 'Stone Color', location: "Yellow" },
  { stoneType: "3", stoneColor: "Location", location: "Tamilnadu / Karnataka" }];


@Component({
  selector: 'app-populate-description',
  templateUrl: './populate-description.component.html',
  styleUrls: ['./populate-description.component.css']
})
export class PopulateDescriptionComponent implements OnInit {

  displayedColumns: string[] = ['stoneType', 'stoneColor', 'location'];
  columnsToDisplay: string[] = this.displayedColumns.slice(); 
  dataSource = ELEMENT_DATA;

  urlString: string;

  titleIvoryGranite: string;
  titleKuppamGreen: string;
  titleClassicIvoryGranite: string;
  titleColonialWhiteGranite: string;
  titleBiancoSatinGranite: string;
  titleImperialWhiteGranite: string;
  titleBashParadisoGranite: string;
  titleKashmirWhiteGranite: string;
  titleRiverWhiteGranite: string;
  titleTigerSkinGranite: string;
  titleBlackGalaxy: string;
  titleBlueGalaxyWhiteGranite: string;
  titleBlueDunesGranite: string;
  titleBlackPearlGranite: string;
  titleRedMulticolorGranite: string;
  titleClassicParadisoGranite: string;
  titleSapphireBlueGranite: string;
  titleBlackForestGranite: string;


  contentIvoryGranite: string;
  contentKuppamGreen: string;
  contentClassicIvoryGranite: string;
  contentColonialWhiteGranite: string;
  contentBiancoSatinGranite: string;
  contentImperialWhiteGranite: string;
  contentBashParadisoGranite: string;
  contentKashmirWhiteGranite: string;
  contentRiverWhiteGranite: string;
  contentTigerSkinGranite: string;
  contentBlackGalaxy: string;
  contentBlueGalaxyWhiteGranite: string;
  contentBlueDunesGranite: string;
  contentBlackPearlGranite: string;
  contentRedMulticolorGranite: string;
  contentClassicParadisoGranite: string;
  contentSapphireBlueGranite: string;
  contentBlackForestGranite: string;

  urlIvoryGranite: string;
  urlKuppamGreen: string;
  urlClassicIvoryGranite: string;
  urlColonialWhiteGranite: string;
  urlBiancoSatinGranite: string;
  urlImperialWhiteGranite: string;
  urlBashParadisoGranite: string;
  urlKashmirWhiteGranite: string;
  urlRiverWhiteGranite: string;
  urlTigerSkinGranite: string;
  urlBlackGalaxy: string;
  urlBlueGalaxyWhiteGranite: string;
  urlBlueDunesGranite: string;
  urlBlackPearlGranite: string;
  urlRedMulticolorGranite: string;
  urlClassicParadisoGranite: string;
  urlSapphireBlueGranite: string;
  urlBlackForestGranite: string;



  //marbles

  titleFantasyBrownMarble: string;
  titleVerdeGuatemalaMarble: string;
  titleRainforestBrownMarble: string;
  titleRainforestGreenMarble: string;
  titleCosmicWavesMarble: string;

  contentFantasyBrownMarble: string;
  contentVerdeGuatemalaMarble: string;
  contentRainforestBrownMarble: string;
  contentRainforestGreenMarble: string;
  contentCosmicWavesMarble: string;

  urlFantasyBrownMarble: string;
  urlVerdeGuatemalaMarble: string;
  urlRainforestBrownMarble: string;
  urlRainforestGreenMarble: string;
  urlCosmicWavesMarble: string;


  //Quartzite
  titleCopperQuartziteProduct: string;
  titleDGreenQuartziteProduct: string;
  titleDYellowQuartziteProduct: string;
  titleGoldenQuartziteProduct: string;
  titleHBlackQuartziteProduct: string;
  titleHGreenQuartziteProduct: string;


  contentCopperQuartziteProduct: string;
  contentDGreenQuartziteProduct: string;
  contentDYellowQuartziteProduct: string;
  contentGoldenQuartziteProduct: string;
  contentHBlackQuartziteProduct: string;
  contentHGreenQuartziteProduct: string;

  urlCopperQuartziteProduct: string;
  urlDGreenQuartziteProduct: string;
  urlDYellowQuartziteProduct: string;
  urlGoldenQuartziteProduct: string;
  urlHBlackQuartziteProduct: string;
  urlHGreenQuartziteProduct: string;

  //slate

  titleJakBlackSlate: string;
  titleJakMulticolorSlate: string;
  titleLilacSlate: string;
  titleMGreenSlate: string;
  titleMultiPinkSlate: string;
  titleMulticolorPeacockSlate: string;


  contentJakBlackSlate: string;
  contentJakMulticolorSlate: string;
  contentLilacSlate: string;
  contentMGreenSlate: string;
  contentMultiPinkSlate: string;
  contentMulticolorPeacockSlate: string;

  urlJakBlackSlate: string;
  urlJakMulticolorSlate: string;
  urlLilacSlate: string;
  urlMGreenSlate: string;
  urlMultiPinkSlate: string;
  urlMulticolorPeacockSlate: string;


  //Sandstone

  titleAgraRedSandstone: string;
  titleAsianGoldSandstone: string;
  titleAutumnBrownSandstone: string;
  titleCamelDustSandstone: string;
  titleDesertMintSandStone: string;
  titleDholpurBeigeSandstone: string;


  contentAgraRedSandstone: string;
  contentAsianGoldSandstone: string;
  contentAutumnBrownSandstone: string;
  contentCamelDustSandstone: string;
  contentDesertMintSandStone: string;
  contentDholpurBeigeSandstone: string;

  urlAgraRedSandstone: string;
  urlAsianGoldSandstone: string;
  urlAutumnBrownSandstone: string;
  urlCamelDustSandstone: string;
  urlDesertMintSandStone: string;
  urlDholpurBeigeSandstone: string;

  //limestone

  titleKotaBlueLimestone: string;
  titleKotaBrownLimestone: string;
  titleKurnoolGrayLimestone: string;
  titleLimeBlackLimestone: string;
  titleLimeGreenLimestone: string;
  titleLimePeacockLimestone: string;


  contentKotaBlueLimestone: string;
  contentKotaBrownLimestone: string;
  contentKurnoolGrayLimestone: string;
  contentLimeBlackLimestone: string;
  contentLimeGreenLimestone: string;
  contentLimePeacockLimestone: string;

  urlKotaBlueLimestone: string;
  urlKotaBrownLimestone: string;
  urlKurnoolGrayLimestone: string;
  urlLimeBlackLimestone: string;
  urlLimeGreenLimestone: string;
  urlLimePeacockLimestone: string;


  //monuments

  titleCrossEngraved: string;
  titleEuropeanStyleGreen: string;
  titleUSAStyleAngelEngraved: string;
  titleSerpentineShaped: string;
  titleHearthShapedGranite: string;
  titleDoubleShapedPolished: string;


  contentCrossEngraved: string;
  contentEuropeanStyleGreen: string;
  contentUSAStyleAngelEngraved: string;
  contentSerpentineShaped: string;
  contentHearthShapedGranite: string;
  contentDoubleShapedPolished: string;

  urlCrossEngraved: string;
  urlEuropeanStyleGreen: string;
  urlUSAStyleAngelEngraved: string;
  urlSerpentineShaped: string;
  urlHearthShapedGranite: string;
  urlDoubleShapedPolished: string;



  titleDisplay: string;
  contentDisplay: string;
  urlDisplay: string;

  constructor(private _Activatedroute: ActivatedRoute, private router: Router) {
    this.titleIvoryGranite = "Ivory Granite";
    this.titleKuppamGreen = "Kuppam Green Granite";
    this.titleClassicIvoryGranite = "Classic Ivory Granite";
    this.titleColonialWhiteGranite = "Colonial White Granite";
    this.titleBiancoSatinGranite = "Bianco Satin Granite";
    this.titleImperialWhiteGranite = "Imperial White Granite";
    this.titleBashParadisoGranite = "Bash Paradiso Granite";
    this.titleKashmirWhiteGranite = "Kashmir White Granite";
    this.titleRiverWhiteGranite = "River White Granite";
    this.titleTigerSkinGranite = "Tiger Skin Granite";
    this.titleBlackGalaxy = "BlackGalaxy";
    this.titleBlueGalaxyWhiteGranite = "Blue Galaxy White Granite"

    this.titleBlueDunesGranite = "Blue Dunes Granite";
    this.titleBlackPearlGranite = "Black Pearl Granite";
    this.titleRedMulticolorGranite = "Red Multicolor Granite";
    this.titleClassicParadisoGranite = "Classic Paradiso Granite";
    this.titleSapphireBlueGranite = " Sapphire Blue Granite";
    this.titleBlackForestGranite = "Black Forest Granite";


    //marbles

    this.titleFantasyBrownMarble = "Fantasy Brown Marble"
    this.titleVerdeGuatemalaMarble = "Verde Guatemala Marble"
    this.titleRainforestBrownMarble = "Rainforest Brown Marble"
    this.titleRainforestGreenMarble = "Rainforest Green Marble"
    this.titleCosmicWavesMarble = "Cosmic Waves Marble"

    //Quartzite
    this.titleCopperQuartziteProduct = "Copper Quartzite Product"
    this.titleDGreenQuartziteProduct = "D Green Quartzite Product"
    this.titleDYellowQuartziteProduct = "D Yellow Quartzite Product"
    this.titleGoldenQuartziteProduct = "Golden Quartzite Product"
    this.titleHBlackQuartziteProduct = "H Black Quartzite Product"
    this.titleHGreenQuartziteProduct = "H Green Quartzite Product"

    //slate

    this.titleJakBlackSlate = "Jack Black Slate"
    this.titleJakMulticolorSlate = "Jack Multicolor Slate"
    this.titleLilacSlate = "Lilac Slate"
    this.titleMGreenSlate = "M-Green Slate"
    this.titleMultiPinkSlate = "Multi Pink Slate"
    this.titleMulticolorPeacockSlate = "Multicolor Peacock Slate"

    //sandstone

    this.titleAgraRedSandstone = "Agra Sandstone"
    this.titleAsianGoldSandstone = "Asian Gold Sandstone"
    this.titleAutumnBrownSandstone = "Autumn Brown Sandstone"
    this.titleCamelDustSandstone = "Camel Dust Sandstone"
    this.titleDesertMintSandStone = "Desert Mint Sandstone"
    this.titleDholpurBeigeSandstone = "Dholpur Beige Sandstone"

    //limestone

    this.titleKotaBlueLimestone = "Kota Blue Limestone"
    this.titleKotaBrownLimestone = "Kota Brown Limestone"
    this.titleKurnoolGrayLimestone = "Kurnool Gray Limestone"
    this.titleLimeBlackLimestone = "Lime Black Limestone"
    this.titleLimeGreenLimestone = "Lime Green Limestone"
    this.titleLimePeacockLimestone = "Lime Peacock Limestone"

    //monuments

    this.titleCrossEngraved = "Cross-Engraved Cats Eye Granite Monumental Stone"
    this.titleEuropeanStyleGreen = "Belgium-style Tropical Green Granite Monument"
    this.titleUSAStyleAngelEngraved = "Angel Engraved Absolute Black Granite Monument product"
    this.titleSerpentineShaped = "Cats Eye Granite Monument"
    this.titleHearthShapedGranite = "Absolute black granite monument product"
    this.titleDoubleShapedPolished = "The European-style polished granite monument"

    //monuments

    this.contentCrossEngraved = "Cross Engraved Cats Eye Granite Monument from Regal StonesExports is a light-colored monument product quarried in South India. This granite monument stone can be availed by you in a right design and shape. Moreover, it is processed at natural stone processing factories in South India. Defined with engraving features, this quality monument stone is available in a particular size and finish. Regal Stonesknows the importance of a monumental stone and offers top quality granite stone products that are enriched with features, including high strength, anti-scratch, long-lasting shine, sturdiness, outstanding surface finish, and smooth texture."
    this.contentEuropeanStyleGreen = "This Belgium style Tropical Green Granite Monument from Regal StonesExports is a light-colored monument product. This South Indian granite monument stone with a perfect shape and design is processed properly in natural stone processing factories. Available at competitive prices, this granite stone product is the best candidate for memorials and tombs. You can opt for this stone in customized size as per your individual needs. With loads of unique features and attractions, this natural stone product overcomes expectations when it comes to giving a superb appearance to tombstones. Following are some details of this stone:"
    this.contentUSAStyleAngelEngraved = "High-quality Angel Engraved USA Model Absolute Black Granite Monument from Regal StonesExports is a light black tomb and monument material with engravings. This stone is processed and quarried in South India and is available for sale at this site at competitive prices. This monument product is the best material for chiseling memorials in a meaningful manner. The certified natural stone company in India knows the value and utility of an unforgettable monumental stone. That is why it offers customized and standard granite stone products that enriched with loads of inherent features. Following are some details of this engraved stone:"
    this.contentSerpentineShaped = "This Half Serp with Flower Engraved Cats Eye Granite Monument from Regal StonesExports is a colorful monument product. This South Indian granite monument stone is processed in natural stone processing factories. Available at an extremely affordable price, this granite stone product for memorials and tombs is ready to serve you with a customized size of your individual needs. Regal Stonesknows what the importance of an unforgettable monumental stone is. In response, the company delivers a range of granite stone products available with loads of features. The stone product supplied by the company is anti-weather, smooth-textured, long-lasting, strong, shiny, superb surface finished, scratch resistance, attractive. Following are some details of this stone:"
    this.contentHearthShapedGranite = "Hearts Europe Model Absolute Black Granite Monument stone from Regal StonesExports is a black monument product from South India. This South Indian granite monument has been processed in South India factories. At a highly competitive price, this heart-shaped product for tombs and memorials can be availed by monument stone seekers in a customized size of their choice and preference. We understand the meaning and utility of a monumental stone and offer you stone products that are famous for various attributes, including long-lasting shine, sturdiness, outstanding surface finish, high strength, anti-scratch, and smooth texture."
    this.contentDoubleShapedPolished = "This European-style, polished, double-shaped granite monument product from Regal StonesExports is a black and brown monument product from South India. This South Indian granite monument stone is elegantly processed in South Indian natural stone processing plants and factories. This European-styled granite monumental stone in two different colors comes with loads of features and attractions, such as superb surface finish, scratch resistance, long-lasting shine, strength, and smooth texture. Regal Stonesunderstands the value and importance of an unforgettable monumental stone and offers well-polished and processed stone. Following are some details of this stone:"

    //slate

    this.contentJakBlackSlate = "Jak black slate is a dark black-colored slate product that delivers a royal and sophisticated touch to the area where it is installed. This slate can produce dramatic effects both in natural and artificial lights. Hence, it is seen as a great material for exterior and interior decoration applications in the form of flooring, wall-covering, and paving. Processed and polished at a modern Jak black slate factory in India, this slate tile product is available in different dimensions and finishes."
    this.contentJakMulticolorSlate = "As its name suggests Jak Multicolor slate features multicolored, rust-like patches on a rich black backdrop. The vibrant tones that accentuate its surface vary from gold, blue, and orange to buff, yellow, and grey. Suitable for both indoor and outdoor applications, this natural stone is capable of casting an aesthetic spell on onlookers. Hallways, kitchens, and conservatories are the best places where you can install this beautiful slate. Processed and polished at a modern Jak multicolor slate factory in India, this slate product in the form of Indian stone tiles has a huge demand in the international market."
    this.contentLilacSlate = "Lilac slate stone comes with multiple shades of purple, pink, grey, red, and yellow that beautifully combine to produce a dramatic effect. Suitable for both residential and commercial projects, one can take advantage of its amazing looks by creating floors, backsplashes, and countertops. Mechanically-processed and elegantly polished at a highly functional slate factory in India, this unique slate product, in the shape of large and small slate tiles, is available in different dimensions."
    this.contentMGreenSlate = "M Green slate is a subtle green colored slate with a uniform texture. It has a moderately riven surface which adds to its natural beauty. This light shaded natural stone with consistent texture easily blends with any interior or exterior. Tough and easy-to-maintain, this slate has an anti-skid surface, which makes it perfect for creating floors and pavements in high-traffic-observing areas like hotels, restaurants, and public parks. Useful for multiple applications, M green slate products are in huge demand for their inherent features."
    this.contentMultiPinkSlate = "Also known as Kashmir Multicolor slate, Multi Pink slate is a pale grey colored slate with waves and swirls in numerous shades of pink that extend across its surface. Owing to its astounding combination of beautiful tones and smooth texture, this natural stone is widely used to boost interior or exterior decor. Apart from wall covering and paving, flooring and building countertops are other popular applications of Multi Pink slate."
    this.contentMulticolorPeacockSlate = "As its name suggests, Multicolor Peacock slate features vibrant shades of red, green, buff, yellow, orange, purple, grey, and brown occurring with different intensities that simply remind us of a peacock feather. Multicolor Peacock slate has a medium-level density and is suited for installations in exterior spaces that observe low to medium traffic. Nevertheless, its interior applications are equally phenomenal."


    //sandstone

    this.contentAgraRedSandstone = "Popularly known as Dholpur Red Sandstone, Agra Red sandstone is found red in color, which is due to the existence of iron oxides near the bedding locations. Since the quarries of this sandstone enjoy proximity to the city of Agra, it is therefore called as Agra Red. This sandstone hasn’t shown any weathering effects since ages as suggested by the present condition of famous historic monuments and temples, including Agra Fort and Delhi’s Red Fort, wherein Agra sandstone was profusely used in the construction. Soft and smooth in texture, this sandstone can be easily cut and carved for a variety of construction purposes."
    this.contentAsianGoldSandstone = "Asian Gold Sandstone is a dazzling sandstone product, which is hard and durable. With a yellow base, this sandstone has orange-colored chips scattered all over its surface, which is responsible for its amazing looks. Commonly used for skirting, wall cladding and making steps, this sandstone in a golden hue is in great demand in the international construction market. A well-known supplier and exporter of Asian Gold sandstone in India, Regal StonesUniversal Exports offers this golden sandstone in standard as well as customized sizes and thicknesses."
    this.contentAutumnBrownSandstone = ": Building stone, ornamental stone, interior, exterior, wall, floor, countertops, sinks, monuments, pool coping, sills, paving stone,Autumn Brown sandstone is durable, hardwearing and quick-drying sandstone, which is easy to be worked upon. It is a creamy brown natural stone having a mixture of dark and light brown tones with touches of plum and grey hues."
    this.contentCamelDustSandstone = "Also known as Sunset Buff, Camel Dust sandstone is creamy yellow colored sandstone with hues of dark brown on its surface. This hard-wearing and low-maintenance sandstone have high resistance toward environmental weathering, abrasion, alkalis, and acids. These, in turn, make dusty sandstone a perfect material for applications in roofing, flooring, paving, and paneling."
    this.contentDesertMintSandStone = "Desert Mint sandstone is elegant sandstone that as yellow as a base color with subtle hints of mint hues. This sandstone can seamlessly compliment any decor with its warm and eye-pleasing shades. It is one such sandstone that can bring a mix of classic and contemporary charm to any decor, whether interior or exterior. Landscape designing, interior wall cladding, and pool surrounds are its common applications."
    this.contentDholpurBeigeSandstone = "Dholpur Beige is an attractive sandstone product of buff white color with even grain size. Veins of purple color on its surface enhance its looks but are only visible when the stone is wet. Its resistance towards, acids, alkalis, and extreme weather conditions make it apt for both outdoor and indoor applications. This durable sandstone can be effortlessly dressed and chiseled, hence considered suitable for roofing, wall cladding, flooring, paneling, and paving."

    //limestone

    this.contentKotaBlueLimestone = "Quarried from the state of Rajasthan, Kota Blue limestone is a light blue limestone product with a surface that has subtle shades of yellow and green. The classy looks, hardness, and ability of the stone to cope with high temperatures make this limestone perfect for both interior and exterior designing. Other characteristics like non-porosity, non-slippery, and stain removability can be attributed to its extensive demand even in the international market. Whether it is about flooring, wall cladding, paving, or construction of facades, Kota Blue has always been a great choice among architects and interior designers around the world. This limestone is convenient to work upon; hence, it can be cut in various sizes. With the great finish, appealing tones, and smooth texture, this stone surely boosts the aesthetics of any surrounding."
    this.contentKotaBrownLimestone = "Quarried from the land of Rajasthan, North India, Kota Brown limestone is a light brown-colored product that comes with yellow and green shades on its surface. With features of hardness, classy looks, and ability to withstand high and low temperatures, this limestone is ideal for both interior and exterior designing projects. In addition, the virtues of non-porosity, non-slippery, and stain removability induce a big demand for this stone even in the international market. Whether you are looking for an ideal stone for flooring, wall cladding, paving, or facades, Kota brown limestone product emerges as a superb choice among architects and interior designers in different parts of the world. This limestone is easy to cut and shape in various sizes and dimensions. This stone simply enhances the aesthetic appeal of surroundings with appealing tones, smooth texture, and great finishes."
    this.contentKurnoolGrayLimestone = "Quarried from Kurnool district of Andhra Pradesh State, India, Kurnool grey limestone is a grey-colored limestone product that is rough in texture. This limestone product is easy to cut and reshape and is extensively used in various residential and commercial construction projects globally. With lots of inherent features like hardness and resistance to high temperatures, Kurnool grey is quite suitable for interior and exterior designing applications. This South Indian limestone product is useful for décor and construction applications, including urban landscape, wall cladding, paving, mosaics, roofing, and flooring."
    this.contentLimeBlackLimestone = "Known by various names, such as Cuddapah Black, Madras Black Limestone, or Midnight Black, Lime Black Limestone is a black colored limestone that is rough in texture. This limestone can be cut and reshaped easily and thus, find extensive use in commercial and residential construction projects all across the world. Properties like excellent stain removability, hardness, and resistance to high temperatures make it the first choice of all for both interior and exterior designing projects and applications. This fine-grained, sedimentary rock is pretty compact and dense in nature; therefore, it is frequently used as a construction material for paving stones, ledgestone, and mosaics for urban landscapes and gardens. Limestone countertops, wall cladding, flooring, roofing, cobbles, and steps are a few other prominent applications of lime black limestone."
    this.contentLimeGreenLimestone = "Famous as a pale green limestone with prominent striations, Lime Green limestone is a tough natural stone product quarried from and processed in South India. Magnificent looks, as well as the hardness of this limestone, make the stone suitable for architectural designing projects at the global level. With the ability to resist harsh climatic conditions and to get better with passing days, Lime Green emerges as the first choice for wall cladding and landscape design projects. Owing to its amazing texture and splendid looks, Lime Green limestone easily adds the old world charm to any interior or exterior, wherever it is installed. Used as pavement stone, wall cladding and flooring material, countertop, veneer, and backsplash, this limestone is widely used in the construction industry around the world."
    this.contentLimePeacockLimestone = "Lime Peacock limestone is a South Indian, dark grey limestone with pale bluish veining pattern that adds perfection to its beauty. In Addition to graceful looks, its hardness, and the ability to withstand extreme thawing conditions significantly contribute to its popularity as construction and architectural designing material all over the world. The anti-skid and oil-resistant properties of Lime peacock further makes it suitable for flooring and paving purposes both in residential and commercial projects. The natural shades of this beautiful natural limestone impart serenity to any ambiance and at the same time, enhance the aesthetics of the same."



    this.contentIvoryGranite = "Ivory fantasy granite is a stone that is renowned for its creamy colors and pinkish tones. Ivory fantasy looks like a predominantly off white stone with a slight pink tinge to it. The off-white reveals itself to be a gorgeous pink tone mainly, with patches of an ivory coloured white peeking through. The pink tone also varies in color, with some places being almost brown in appearance and others being a dark peach color. There are darker mineral deposits too that are much less frequent in size and density. These can range from a grey to a very dark blue in color and create a very dramatic effect. Ivory fantasy granite does not vary very much with light conditions, and is suitable for use in rooms both with and without a lot of natural light.";
    this.contentKuppamGreen = "Kuppam green is a from of Indian granite originating out of Shoolagiri Belt, in Southern India. This granite has light green grey background with more darker and lighter cloudy pattern. This granite has many similarity of texture with Viscount White Granite. It has a greyish green color with green mixed with white that has a beautiful veining across its surface. The remarkable stone brings a climate of the tropical nature into your environment with style and extravagance. The colour is notable and associated with health, balance, and vitality, which creates an atmosphere of relaxation and comfort."
    this.contentClassicIvoryGranite = "Classic Ivory is a form of Indian Granite originating out of Southern India. Classic Ivory granite has a white background with dapples and veins of gray and dark red. Polished granite is also a popular choice for kitchen countertops due to its high durability and aesthetic qualities."
    this.contentColonialWhiteGranite = "Colonial White Granite, also known as Millennium Cream, features white / ivory patterns, with gold and brown veins. Colonial White has natural earthy tones that makes brushed finish a desirable option for people looking to create a more natural look in their home. Having a light white / creamy base, the stone proves to be the perfect choice for all those who want to invest in something which will not overpower the rest of a rooms décor but yet will add its own charm and elegance to the overall appearance."
    this.contentBiancoSatinGranite = "Bianco Satin granite is a stone loved for its crisp white bedrock and stunning brown mineral deposits. Clear quartz crystals are scattered throughout the stone, these are only slightly different in tone from the bedrock but give the stone an overall shine. Very light grey patches are also littered around the stone. These are very subtle and are not immediately noticeable in most cases. They add an extra layer of depth to the pure white bedrock that is not too overpowering – ensuring the clean and fresh appearance is not disturbed. Little black mineral deposits are also infrequently dotted around the stone, they are not very common and most slabs will only have a handful of them. They are usually found inside one of the brown mineral deposits and can add a little contrast to the stone."
    this.contentImperialWhiteGranite = "Imperial white granite is a stone that is renowned for its beauty. The predominant color of Imperial White Granite is a white which can range in color from brilliant white all the way to an almost grey color. Speckled over this beautiful combination of white stone and grey minerals, is a black mineral. It is only usually found in isolated spots and really adds some interest and depth when the stone is viewed up close. These black minerals are nearly invisible from a distance – which means imperial white granite looks very white and grey from afar. This type of granite has a medium level of variation. The main variance will be the density of the grey veins that make up a vast amount of this stone. They can be barely visible creating a very clean and white look."
    this.contentBashParadisoGranite = "Paradiso Bash granite is a natural stone from Krishnagiri District in India comprising a delicate array of multicoloured minerals that provide a unique and striking effect in any room in which it is displayed. Paradiso Bash Granite is a brown-grey granite from India. This granite has cloudy structure. The abstract and wild pattern gives this stone its unique identity."
    this.contentKashmirWhiteGranite = "Kashmir White Granite is a predominantly white stone, but its overall appearance is generally a very light grey in color due to the mineral deposits. The white bedrock reveals its self to be an even brighter and lighter white than it appeared to be from afar. It is as pure as pure can be. What gives this stone a slightly darker color from a distance is the little mineral deposits that are littered throughout the stone. The main mineral that is present is a light grey mineral that can range from only very slightly darker than the white bedrock – to a medium grey in places. What makes this mineral extra special is that in places it can have a very light red tinge to it. Dotted around the stone will also be spots of red mineral deposits. These are usually very small in size and rarely exceed the size of a water droplet. These red mineral deposits are usually a very deep dark red / burgundy and elegantly add a little color to the stone. This granite does not really vary all that much in rooms with different lighting conditions. The light brown mineral patches may be a little less visible in rooms without much natural light"
    this.contentRiverWhiteGranite = "River White Granite is a form of Indian Granite originating out of Visakhapatnam region in Andhra Pradesh. This granite is a predominantly off white stone that has a grey tinge to it. Its appearance can look reasonably uniform in its color. The bedrock of this granite is a very light grey in color – almost white. Littered throughout the stone are long winding veins of a grey mineral. This mineral can vary in color from a light grey through to a dark grey in color. What river white granite is most famous for is its stunning tiny red mineral deposits and they are truly magnificent. They are usually a red that is slightly lighter than burgundy – but can look a little brighter due to being set against the light greys and whites of the bedrock. There is a low amount of variation within different slabs of this granite. The main factors that will vary are the amount of the red minerals that are present and the color of the grey minerals. The grey minerals can differ in their shade, but are usually always light. This granite does not look that different when used in rooms with different lighting conditions – and it will look great in rooms with and without much natural light. The stunning white tones of this granite can contrast and complement a whole wide range of colors."
    this.contentTigerSkinGranite = "Tiger Skin is form of Indian Granite originating out Rajasthan region.This granite has brown, orange and red background with black waves in the pattern that forms a texture looking like a tiger’s skin, hence the name. It has is mostly consistent and does not differ a lot in pattern and texture. This is quarried in Rajasthan State of India.";
    this.contentBlackGalaxy = "Black galxy granite is very popular form of Indian Granite originating out of Ongole in Prakasam district of Andhra Pradesh. Black galaxy has pitch dark black background with golden and silver speckles which gives it a look of a night sky filled with stars. Black Galaxy has very even patterns and can be used for indoor and outdoor uses, and is ideal for tiles, countertops, and table tops. It is also ideal for projects.";
    this.contentBlueGalaxyWhiteGranite = "White Galaxy Blue is form a Indian Granite originating from the Rajasthan region. White Galaxy has a very distinct white or cream base with brown and blue flowers, and specs ans swirls of blue and grey. It also has striking grey swirls and blue specks. These garnets and specks shine like stars. This granite is quarried in the Indian State of Andhra Pradesh. This is quarried near the coastal areas. It is a very low variation of granite and the uniformity makes this granite a very premium and luxurious."
    this.contentBlueDunesGranite = "Blue Dunes is a form of Indian granite with blue and green background and brown streaks that form a dunes like pattern. Blue Dunes is also known as Azul Celeste Granite. This stone has an earth tone beauty with smooth symmetrical flow creating with an all natural look and a modern flow. This granite is very popular for kitchen countertops and for use in washrooms. It is also good for use in flooring, washroom tiles, countertops and wall clading.";
    this.contentBlackPearlGranite = "Black Pearl is a form of Indian Granite origitnating out of Chilikr District in Telengana (formerly Andhra Pradesh) is commonly also known as silver pearl granite. The bedrock of this stone is a deep dark black in color. A stunning grey mineral makes up the bulk of this stones mass. It’s reasonably uniform in its color and can range from a medium grey, to a slightly dark grey – however it can often have a slightly blue tinge to it. It has very consistent gradient and texture and is perfect for large scale infrastructure projects. It can be used for indoor as well as outdoor use for wall clading, countertops and flooring.";
    this.contentRedMulticolorGranite = "Red multi color is a form of Indian granite originating out of Southern India. This particular granite is a blend of different shades where red dominates the other colors. The base color of this granite is red and there is a combination of brilliant colors like browns, yellows, whites, lavenders etc. Red multi color granites are medium grained and are available in light and dark shade also. This granite is a very hard granite and takes excellent polishing. The background of this granite is red and colored with thin black and brown multicolor waves.";
    this.contentClassicParadisoGranite = "Classic Paradiso is a form of Granite found in Dharmapuri District, Tamilnadu. Paradiso Classico granite comprises of delicate array of multicoloured minerals that provide a unique and striking effect in any room in which it is displayed. This granite purple background with very thin waves of grey and red colors that create a cloudy pattern. Classic Paradiso Granite has medium water absorption and can be used for Indoor and Outdoor application.";
    this.contentSapphireBlueGranite = "Sapphire Blue is a stunning blue and brown granite found in Warangal District, Andhra Pradesh. Sapphire blue gets its name from its predominant mesmerizing deep blue color which is the main color of the bedrock from which this granite is made.";
    this.contentBlackForestGranite = "Black Forest is a form of Granite found in Andhra Pradesh, India. This granite has dark background with white veins which are like a spider net. veins intersects sometimes and gives the look of a dark forest. The stone ranges from astonishingly subtle lines to widely perceptible, among many other spectacular aspects in this grace. Its charm and elegance will look splendid in any given space and its usage lasts for decades. This granite has many similarity of texture with Paradisso Granite and also called as Silver Paradisso. This granite is primly used for Kitchen Countertops, wall tiles and flooring.";
    //marbles:

    this.contentFantasyBrownMarble = "Fantasy Brown is a form of marble found in the Rajasthan region of India. This is one of the most popular exotic marbles from India with streaks of white and different shades of brown. The pattern movement in this form of mable is very consistent and calming to look at. The marble has white or brownish background with brown and grey texture. This form of marble is used for countertops and backsplash tiles of slates."
    this.contentVerdeGuatemalaMarble = "Verde Guatemala is a form if Marble found in Keshariya Ji which is in Udaipur District, Rajasthan. It has low water absorption and high flexural strength making it flexible for both indoor and outdoor application. It is processed, supplied and exported in various sizes."
    this.contentRainforestBrownMarble = "Rajasthan Fantasy Brown is a form of Marble found in Churu District in Rajasthan. It has high water absorption making it ideal for indoor applications only. It is processed, supplied and exported in various sizes."
    this.contentRainforestGreenMarble = "Rainforest Green is a form of Marble found in Churu District in Rajasthan. Rainforest Green is a natural stone from India, comprising an elegant pattern of green, brown and golden veins, on a delicate earthy green base. It has high water absorption and low flexural strength making it ideal for indoor applications only. It is processed, supplied and exported in various sizes."
    this.contentCosmicWavesMarble = "Cosmic waves is form of Marble found in Kothputli, Jaipur District in Rajasthan, India. It has dotted streaks of black and white that create a beautiful abstract pattern that is soothing to look at. It has very low water absorption and can be used for indoor as well as outdoor application. Cosmic waves is processed, supplied and exported in various sizes."

    //Quartzite
    this.contentCopperQuartziteProduct = "Copper quartzite is a pale reddish-brown quartzite with attractive patterns in darker shades over its surface. Its unique looks are complemented by amazing texture and excellent properties that eventually make it apt for both indoor and outdoor applications. This stone is universally used for flooring, roofing, paving and wall cladding."
    this.contentDGreenQuartziteProduct = "Deouli Green or simply D Green is a stunning quartzite with a dark green base. Numerous patterns in yellow, beige and grey shades occur on its surface are responsible for the natural looks of the stone. Suitable for residential and commercial projects, this quartzite is widely used for external aesthetics, including flooring, paving, roofing and wall cladding."
    this.contentDYellowQuartziteProduct = "Deouli Yellow or just D Yellow has a yellow base with streaks and patterns on its surface in multiple shades of buff, yellow, grey and orange. Due to its eye-pleasing looks, durability and heat/frost resistant nature, this quartzite is extensively used for exterior and interior applications in the forms of floor tiles, wall panels and paving stones."
    this.contentGoldenQuartziteProduct = "Golden quartzite is a rustic golden quartzite with huge variations. To add to its beauty are swirls in dark grey shades and streaks in sunset orange tones. Low permeability, anti-skid feature, durability and frost resistance are several properties of this quartzite that make it suitable for both exterior and interior applications."
    this.contentHBlackQuartziteProduct = "H Black quartzite is a black quartzite, known for its stately looks. It has a medium-grained texture and can be cut into pavements, slabs, flags, and tiles. Inclusive of wall covering, roofing and flooring, this quartzite is used for both exterior and interior decoration. Regal StonesUniversal Exports give prime value to the needs of quartzite tiles lovers when they search for special countertops. From per square feet price of quartzite countertops to timely delivery of the final products, Regal Stonesgives you an edge. The following are particulars showing how we make things happen in your favor."
    this.contentHGreenQuartziteProduct = "H Green quartzite has a green base with consistent texture. Occasional hints of buff and grey tones on its surface add to its beauty. Its anti-skid and fire-proof features make it suitable for wall coverings and flooring applications. The way this stone makes the surrounding visually appealing is simply matchless. Regal StonesUniversal Exports is a leading and certified natural stone supplier and manufacturer that supplies and exports high-quality quartzite products in all corners of the world. We are ready to serve all with the following specifications:"


    this.urlIvoryGranite = "/assets/images/ivory-fantasy-granite.jpg";
    this.urlKuppamGreen = "/assets/images/Kuppam-Green.jpg";
    this.urlClassicIvoryGranite = "/assets/images/Granite-classic-ivory.jpg";
    this.urlColonialWhiteGranite = "/assets/images/Colonial-white.jpg";
    this.urlBiancoSatinGranite = "/assets/images/Bianco-Satin.jpg";
    this.urlImperialWhiteGranite = "/assets/images/Imperial-White-granite.jpg";
    this.urlBashParadisoGranite = "/assets/images/bash-paradiso.jpg";
    this.urlKashmirWhiteGranite = "/assets/images/KASHMIR-WHITE-Granite.jpg";
    this.urlRiverWhiteGranite = "/assets/images/River-White.jpg";
    this.urlTigerSkinGranite = "/assets/images/Tiger-Skin.jpg"
    this.urlBlackGalaxy = "/assets/images/Black-Galaxy.jpg"
    this.urlBlueGalaxyWhiteGranite = "/assets/images/Blue-Galaxy-White.jpg"

    this.urlBlueDunesGranite = "/assets/images/Blue-dunes.jpg";
    this.urlBlackPearlGranite = "/assets/images/Black-Pearl.jpg";
    this.urlRedMulticolorGranite = "/assets/images/Red-multicolor.png";
    this.urlClassicParadisoGranite = "/assets/images/Classic-Paradiso.png";
    this.urlSapphireBlueGranite = "/assets/images/Sapphire-blue.jpg";
    this.urlBlackForestGranite = "/assets/images/black-forest-granite.jpg";
    

    //marble

    this.urlFantasyBrownMarble = "/assets/images/fantasy-brown-marble.jpg"
    this.urlVerdeGuatemalaMarble = "/assets/images/Verde-Guatemala.jpg"
    this.urlRainforestBrownMarble = "/assets/images/Rajasthan-Fantasy-Brown.jpg"
    this.urlRainforestGreenMarble = "/assets/images/Rajasthan-Fancy-Green.jpg"
    this.urlCosmicWavesMarble = "/assets/images/Cosmic-Waves.jpg"

    //Quartzite

    this.urlCopperQuartziteProduct = "/assets/images/Copper-Quart.jpg"
    this.urlDGreenQuartziteProduct = "/assets/images/D-Green.jpg"
    this.urlDYellowQuartziteProduct = "/assets/images/D-Yellow.jpg"
    this.urlGoldenQuartziteProduct = "/assets/images/Golden.jpg"
    this.urlHBlackQuartziteProduct = "/assets/images/H-Black.jpg"
    this.urlHGreenQuartziteProduct = "/assets/images/H-green.jpg"

    //slate

    this.urlJakBlackSlate = "/assets/images/Jak-Black-Slate.jpg"
    this.urlJakMulticolorSlate = "/assets/images/Jak-Multicolor-Slate.jpg"
    this.urlLilacSlate = "/assets/images/Lilac-Tiles.jpg"
    this.urlMGreenSlate = "/assets/images/M-Green-Slate.jpg"
    this.urlMultiPinkSlate = "/assets/images/Multi-Pink-Slate.jpg"
    this.urlMulticolorPeacockSlate = "/assets/images/Multicolor-Peacock-Tiles.jpg"

    //sandstone

    this.urlAgraRedSandstone = "/assets/images/agra-sand-stone.jpg"
    this.urlAsianGoldSandstone = "/assets/images/Asian-Gold.jpg"
    this.urlAutumnBrownSandstone = "/assets/images/Autumn-Brown.jpg"
    this.urlCamelDustSandstone = "/assets/images/Camel-Dust.jpg"
    this.urlDesertMintSandStone = "/assets/images/Desert-Mint.jpg"
    this.urlDholpurBeigeSandstone = "/assets/images/Dholpur-Beige.jpg"

    //limestone

    this.urlKotaBlueLimestone = "/assets/images/Kota-Blue.jpg"
    this.urlKotaBrownLimestone = "/assets/images/Kota-Brown.jpg"
    this.urlKurnoolGrayLimestone = "/assets/images/Kurnool-Grey-Limestone.jpg"
    this.urlLimeBlackLimestone = "/assets/images/lime-black-limestone.jpg"
    this.urlLimeGreenLimestone = "/assets/images/lime-green-limestone.jpg"
    this.urlLimePeacockLimestone = "/assets/images/lime-peacock.jpg"

    //monuments

    this.urlCrossEngraved = "/assets/images/RGM-006.jpg"
    this.urlEuropeanStyleGreen = "/assets/images/RGM-004.jpg"
    this.urlUSAStyleAngelEngraved = "/assets/images/RGM-011.jpg"
    this.urlSerpentineShaped = "/assets/images/RGM-003.jpg"
    this.urlHearthShapedGranite = "/assets/images/RGM-001.jpg"
    this.urlDoubleShapedPolished = "/assets/images/RGM-005.jpg"



    router.events.subscribe((val) => {
      this.updateView();
      // see also 
    });
  }

  ngOnInit(): void {
    console.log("the value that we are looking for is : " + this.urlString);
    this.updateView();
  }

  updateView() {
    this.urlString = this._Activatedroute.snapshot.paramMap.get("id");
    if (this.urlString == "granite-01") {
      this.contentDisplay = this.contentIvoryGranite;
      this.titleDisplay = this.titleIvoryGranite;
      this.urlDisplay = this.urlIvoryGranite;
      this.updateTable("Granite", "Yellow", "Tamilnadu / Karnataka")
    }

    else if (this.urlString == "granite-02") {
      this.contentDisplay = this.contentKuppamGreen;
      this.titleDisplay = this.titleKuppamGreen;
      this.urlDisplay = this.urlKuppamGreen;
      this.updateTable("Granite", "Green", "Tamilnadu / Karnataka")
    }

    else if (this.urlString == "granite-03") {
      this.contentDisplay = this.contentClassicIvoryGranite;
      this.titleDisplay = this.titleClassicIvoryGranite;
      this.urlDisplay = this.urlClassicIvoryGranite;
      this.updateTable("Granite", "Yellow", "Tamilnadu / Karnataka")
    }

    else if (this.urlString == "granite-04") {
      this.contentDisplay = this.contentColonialWhiteGranite;
      this.titleDisplay = this.titleColonialWhiteGranite;
      this.urlDisplay = this.urlColonialWhiteGranite;
      this.updateTable("Granite", "White", "Tamilnadu / Karnataka")
    }

    else if (this.urlString == "granite-05") {
      this.contentDisplay = this.contentBiancoSatinGranite;
      this.titleDisplay = this.titleBiancoSatinGranite;
      this.urlDisplay = this.urlBiancoSatinGranite;
      this.updateTable("Granite", "Gray", "Rajasthan")
    }

    else if (this.urlString == "granite-06") {
      this.contentDisplay = this.contentImperialWhiteGranite;
      this.titleDisplay = this.titleImperialWhiteGranite;
      this.urlDisplay = this.urlImperialWhiteGranite;
      this.updateTable("Granite", "White", "Tamilnadu / Karnataka")
    }

    else if (this.urlString == "granite-07") {
      this.contentDisplay = this.contentBashParadisoGranite;
      this.titleDisplay = this.titleBashParadisoGranite;
      this.urlDisplay = this.urlBashParadisoGranite;
      this.updateTable("Granite", "Brown", "Tamilnadu / Karnataka")
    }

    else if (this.urlString == "granite-08") {
      this.contentDisplay = this.contentKashmirWhiteGranite;
      this.titleDisplay = this.titleKashmirWhiteGranite;
      this.urlDisplay = this.urlKashmirWhiteGranite;
      this.updateTable("Granite", "White", "Kashmir")
    }

    else if (this.urlString == "granite-09") {
      this.contentDisplay = this.contentRiverWhiteGranite;
      this.titleDisplay = this.titleRiverWhiteGranite;
      this.urlDisplay = this.urlRiverWhiteGranite;
      this.updateTable("Granite", "White", "Andhra Pradesh / Telengana")
    }

    else if (this.urlString == "granite-10") {
      this.contentDisplay = this.contentTigerSkinGranite;
      this.titleDisplay = this.titleTigerSkinGranite;
      this.urlDisplay = this.urlTigerSkinGranite;
      this.updateTable("Granite", "Brown", "Rajasthan")
    }

    else if (this.urlString == "granite-11") {
      this.contentDisplay = this.contentBlackGalaxy;
      this.titleDisplay = this.titleBlackGalaxy;
      this.urlDisplay = this.urlBlackGalaxy;
      this.updateTable("Granite", "Black", "Andhra Pradesh / Telengana")
    }

    else if (this.urlString == "granite-12") {
      this.contentDisplay = this.contentBlueGalaxyWhiteGranite;
      this.titleDisplay = this.titleBlueGalaxyWhiteGranite;
      this.urlDisplay = this.urlBlueGalaxyWhiteGranite;
      this.updateTable("Granite", "White", "Rajasthan")
    }

    else if (this.urlString == "granite-18") {
      this.contentDisplay = this.contentBlueDunesGranite;
      this.titleDisplay = this.titleBlueDunesGranite;
      this.urlDisplay = this.urlBlueDunesGranite;
      this.updateTable("Granite", "White", "Rajasthan")
    }

    else if (this.urlString == "granite-13") {
      this.contentDisplay = this.contentBlackPearlGranite;
      this.titleDisplay = this.titleBlackPearlGranite;
      this.urlDisplay = this.urlBlackPearlGranite;
      this.updateTable("Granite", "Black", "Andhra Pradesh")
    }

    else if (this.urlString == "granite-14") {
      this.contentDisplay = this.contentRedMulticolorGranite;
      this.titleDisplay = this.titleRedMulticolorGranite;
      this.urlDisplay = this.urlRedMulticolorGranite;
      this.updateTable("Granite", "White", "Rajasthan")
    }

    else if (this.urlString == "granite-15") {
      this.contentDisplay = this.contentClassicParadisoGranite;
      this.titleDisplay = this.titleClassicParadisoGranite;
      this.urlDisplay = this.urlClassicParadisoGranite;
      this.updateTable("Granite", "White", "Rajasthan")
    }

    else if (this.urlString == "granite-16") {
      this.contentDisplay = this.contentSapphireBlueGranite;
      this.titleDisplay = this.titleSapphireBlueGranite;
      this.urlDisplay = this.urlSapphireBlueGranite;
      this.updateTable("Granite", "White", "Rajasthan")
    }

    else if (this.urlString == "granite-17") {
      this.contentDisplay = this.contentBlackForestGranite;
      this.titleDisplay = this.titleBlackForestGranite;
      this.urlDisplay = this.urlBlackForestGranite;
      this.updateTable("Granite", "Black", "Andhra Pradesh")
    }

    //marble

    else if (this.urlString == "marble-01") {
      this.contentDisplay = this.contentFantasyBrownMarble;
      this.titleDisplay = this.titleFantasyBrownMarble;
      this.urlDisplay = this.urlFantasyBrownMarble;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "marble-02") {
      this.contentDisplay = this.contentVerdeGuatemalaMarble;
      this.titleDisplay = this.titleVerdeGuatemalaMarble;
      this.urlDisplay = this.urlVerdeGuatemalaMarble;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "marble-03") {
      this.contentDisplay = this.contentCosmicWavesMarble;
      this.titleDisplay = this.titleCosmicWavesMarble;
      this.urlDisplay = this.urlCosmicWavesMarble;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "marble-04") {
      this.contentDisplay = this.contentRainforestBrownMarble;
      this.titleDisplay = this.titleRainforestBrownMarble;
      this.urlDisplay = this.urlRainforestBrownMarble;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "marble-05") {
      this.contentDisplay = this.contentRainforestGreenMarble;
      this.titleDisplay = this.titleRainforestGreenMarble;
      this.urlDisplay = this.urlRainforestGreenMarble;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    //Quartzite

    else if (this.urlString == "quart-01") {
      this.contentDisplay = this.contentCopperQuartziteProduct;
      this.titleDisplay = this.titleCopperQuartziteProduct;
      this.urlDisplay = this.urlCopperQuartziteProduct;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "quart-02") {
      this.contentDisplay = this.contentDGreenQuartziteProduct;
      this.titleDisplay = this.titleDGreenQuartziteProduct;
      this.urlDisplay = this.urlDGreenQuartziteProduct;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "quart-03") {
      this.contentDisplay = this.contentDYellowQuartziteProduct;
      this.titleDisplay = this.titleDYellowQuartziteProduct;
      this.urlDisplay = this.urlDYellowQuartziteProduct;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "quart-04") {
      this.contentDisplay = this.contentHBlackQuartziteProduct;
      this.titleDisplay = this.titleHBlackQuartziteProduct;
      this.urlDisplay = this.urlHBlackQuartziteProduct;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "quart-05") {
      this.contentDisplay = this.contentHGreenQuartziteProduct;
      this.titleDisplay = this.titleHGreenQuartziteProduct;
      this.urlDisplay = this.urlHGreenQuartziteProduct;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "quart-06") {
      this.contentDisplay = this.contentGoldenQuartziteProduct;
      this.titleDisplay = this.titleGoldenQuartziteProduct;
      this.urlDisplay = this.urlGoldenQuartziteProduct;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    //Slate
    else if (this.urlString == "slate-01") {
      this.contentDisplay = this.contentJakBlackSlate;
      this.titleDisplay = this.titleJakBlackSlate;
      this.urlDisplay = this.urlJakBlackSlate;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "slate-02") {
      this.contentDisplay = this.contentLilacSlate;
      this.titleDisplay = this.titleLilacSlate;
      this.urlDisplay = this.urlLilacSlate;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "slate-03") {
      this.contentDisplay = this.contentMGreenSlate;
      this.titleDisplay = this.titleMGreenSlate;
      this.urlDisplay = this.urlMGreenSlate;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "slate-04") {
      this.contentDisplay = this.contentMultiPinkSlate;
      this.titleDisplay = this.titleMultiPinkSlate;
      this.urlDisplay = this.urlMultiPinkSlate;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "slate-05") {
      this.contentDisplay = this.contentJakMulticolorSlate;
      this.titleDisplay = this.titleJakMulticolorSlate;
      this.urlDisplay = this.urlJakMulticolorSlate;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "slate-06") {
      this.contentDisplay = this.contentMulticolorPeacockSlate;
      this.titleDisplay = this.titleMulticolorPeacockSlate;
      this.urlDisplay = this.urlMulticolorPeacockSlate;
      this.updateTable("Marble", "Green", "Rajasthan")
    }


    //Slate
    else if (this.urlString == "sandstone-01") {
      this.contentDisplay = this.contentAgraRedSandstone;
      this.titleDisplay = this.titleAgraRedSandstone;
      this.urlDisplay = this.urlAgraRedSandstone;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "sandstone-02") {
      this.contentDisplay = this.contentAsianGoldSandstone;
      this.titleDisplay = this.titleAsianGoldSandstone;
      this.urlDisplay = this.urlAsianGoldSandstone;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "sandstone-03") {
      this.contentDisplay = this.contentCamelDustSandstone;
      this.titleDisplay = this.titleCamelDustSandstone;
      this.urlDisplay = this.urlCamelDustSandstone;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "sandstone-04") {
      this.contentDisplay = this.contentAutumnBrownSandstone;
      this.titleDisplay = this.titleAutumnBrownSandstone;
      this.urlDisplay = this.urlAutumnBrownSandstone;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "sandstone-05") {
      this.contentDisplay = this.contentDesertMintSandStone;
      this.titleDisplay = this.titleDesertMintSandStone;
      this.urlDisplay = this.urlDesertMintSandStone;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "sandstone-06") {
      this.contentDisplay = this.contentDholpurBeigeSandstone;
      this.titleDisplay = this.titleDholpurBeigeSandstone;
      this.urlDisplay = this.urlDholpurBeigeSandstone;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    //limestone

    else if (this.urlString == "limestone-01") {
      this.contentDisplay = this.contentKotaBlueLimestone;
      this.titleDisplay = this.titleKotaBlueLimestone;
      this.urlDisplay = this.urlKotaBlueLimestone;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "limestone-02") {
      this.contentDisplay = this.contentKotaBrownLimestone;
      this.titleDisplay = this.titleKotaBrownLimestone;
      this.urlDisplay = this.urlKotaBrownLimestone;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "limestone-03") {
      this.contentDisplay = this.contentKurnoolGrayLimestone;
      this.titleDisplay = this.titleKurnoolGrayLimestone;
      this.urlDisplay = this.urlKurnoolGrayLimestone;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "limestone-04") {
      this.contentDisplay = this.contentLimeBlackLimestone;
      this.titleDisplay = this.titleLimeBlackLimestone;
      this.urlDisplay = this.urlLimeBlackLimestone;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "limestone-05") {
      this.contentDisplay = this.contentLimeGreenLimestone;
      this.titleDisplay = this.titleLimeGreenLimestone;
      this.urlDisplay = this.urlLimeGreenLimestone;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "limestone-06") {
      this.contentDisplay = this.contentLimePeacockLimestone;
      this.titleDisplay = this.titleLimePeacockLimestone;
      this.urlDisplay = this.urlLimePeacockLimestone;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    //monuments

    else if (this.urlString == "monument-01") {
      this.contentDisplay = this.contentCrossEngraved;
      this.titleDisplay = this.titleCrossEngraved;
      this.urlDisplay = this.urlCrossEngraved;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "monument-02") {
      this.contentDisplay = this.contentEuropeanStyleGreen;
      this.titleDisplay = this.titleEuropeanStyleGreen;
      this.urlDisplay = this.urlEuropeanStyleGreen;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "monument-03") {
      this.contentDisplay = this.contentUSAStyleAngelEngraved;
      this.titleDisplay = this.titleUSAStyleAngelEngraved;
      this.urlDisplay = this.urlUSAStyleAngelEngraved;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "monument-04") {
      this.contentDisplay = this.contentSerpentineShaped;
      this.titleDisplay = this.titleSerpentineShaped;
      this.urlDisplay = this.urlSerpentineShaped;
      this.updateTable("Marble", "Brown", "Rajasthan")
    }

    else if (this.urlString == "monument-05") {
      this.contentDisplay = this.contentHearthShapedGranite;
      this.titleDisplay = this.titleHearthShapedGranite;
      this.urlDisplay = this.urlHearthShapedGranite;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

    else if (this.urlString == "monument-06") {
      this.contentDisplay = this.contentDoubleShapedPolished;
      this.titleDisplay = this.titleDoubleShapedPolished;
      this.urlDisplay = this.urlDoubleShapedPolished;
      this.updateTable("Marble", "Green", "Rajasthan")
    }

  }

  updateTable(stoneType, stoneColor, location) {
    ELEMENT_DATA[0].stoneType = "1"
    ELEMENT_DATA[1].stoneType = "2"
    ELEMENT_DATA[2].stoneType = "3"

    ELEMENT_DATA[0].stoneColor = "Stone Type"
    ELEMENT_DATA[1].stoneColor = "Stone Color"
    ELEMENT_DATA[2].stoneColor = "Location"

    ELEMENT_DATA[0].location = stoneType
    ELEMENT_DATA[1].location = stoneColor
    ELEMENT_DATA[2].location = location
  }

}
