import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
declare var Flickity: any;

@Component({
  selector: 'app-home-page-carousle',
  templateUrl: './home-page-carousle.component.html',
  styleUrls: ['./home-page-carousle.component.css']
})
export class HomePageCarousleComponent implements AfterViewInit {

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    const el = this.el.nativeElement;

    const elem = el.querySelector('.carousel');
    new Flickity(elem, {
      imagesLoaded: true,
      percentPosition: false,
      wrapAround: true,
      autoPlay: true
    });
  }

}
