import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quartzite',
  templateUrl: './quartzite.component.html',
  styleUrls: ['./quartzite.component.css']
})
export class QuartziteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
