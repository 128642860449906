<div style="width: 100%; background: #E2E2E2;">
    <div class="container">

        <div class="row" style="min-height: 5vh;"> </div>
        <div class="row">
            <div class="col-sm-4" (click)="navigateToQuart_01();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Copper-Quart.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>

                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Copper Quartzite Product 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Creamy colors and pinkish tones
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToQuart_02();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/D-Green.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color: #E2E2E2;">
                            D Green Quartzite Product 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: #E2E2E2;">
                            Green grey background with more darker and lighter cloudy pattern
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToQuart_03();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/D-Yellow.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText">
                            D Yellow Quartzite Product
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Has a white background with dapples and veins
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToQuart_04();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/H-Black.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            H Black Quartzite Product 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            White / ivory patterns, with gold and brown veins
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToQuart_05();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/H-green.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:black">
                            H Green Quartzite Product 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Loved for its crisp white bedrock and stunning brown mineral deposits
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToQuart_06();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Golden.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color:black">
                            Golden Quartzite Product 
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Renowned for its beauty
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

</div>