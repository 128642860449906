<div style="background: rgba(22, 25, 37, 0);">
    <div class="carousel" data-flickity='{ "imagesLoaded": true, "percentPosition": false }'>
        <div class="setBackGround" style="background-image: url('/assets/images/granite.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Granite
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    Bringing Design to the Surface
                </div>
            </div>
        </div>

        <div class="setBackGround" style="background-image: url('/assets/images/marble.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Marble
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    Create Your Dream Room.
                </div>
            </div>
        </div>

        <div class="setBackGround" style="background-image: url('/assets/images/limestone.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Limestone
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    Do It Once. Do It With Stone.
                </div>
            </div>
        </div>

        <div class="setBackGround" style="background-image: url('/assets/images/quartzite.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Quartzite
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    For a Polished Surface That Lasts.
                </div>
            </div>
        </div>

        <div class="setBackGround" style="background-image: url('/assets/images/sandstone.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Sandstone
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    Imagine What’s Possible.
                </div>
            </div>
        </div>

        <div class="setBackGround" style="background-image: url('/assets/images/slate.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Slate
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    Rock & Stone Wonders For Your Home.
                </div>
            </div>
        </div>

        <div class="setBackGround" style="background-image: url('/assets/images/H-Black.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Granite
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    Style is the New Standard.
                </div>
            </div>
        </div>

        <div class="setBackGround" style="background-image: url('/assets/images/H-green.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Granite
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    Timeless Style and the Finest Design.
                </div>
            </div>
        </div>

        <div class="setBackGround" style="background-image: url('/assets/images/Imperial-White-granite.jpg')">
            <div class="centerAlign">
                <div class="marginLowLow"></div>

                <div class="marginLow"></div>
                <div class="fontForSubText">
                    Granite
                </div>
                <div class="marginLow"></div>
                <div class="fontForSubSubText">
                    We Stand Behind What You Stand On.
                </div>
            </div>
        </div>
    </div>
</div>
<div style="min-height: 5vh;"></div>