import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';


//Lottie
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

//material imports
import { MatInputModule} from '@angular/material/input' 
import {MatButtonModule} from '@angular/material/button'
import {MatSelectModule} from '@angular/material/select'; 
import {MatFormFieldModule} from '@angular/material/form-field'; 
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatPaginatorModule} from '@angular/material/paginator'
import {MatSortModule} from '@angular/material/sort'
import {MatTableModule} from '@angular/material/table'; 
import { MatSliderModule } from '@angular/material/slider';
import {MatToolbarModule} from '@angular/material/toolbar'
import{MatIconModule} from '@angular/material/icon'
import{MatSidenavModule} from '@angular/material/sidenav'
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import { FirstPageComponent } from './first-page/first-page.component';
import { SecondPageComponent } from './second-page/second-page.component';
import { ThirdPageComponent } from './third-page/third-page.component';
import { ForthPageComponent } from './forth-page/forth-page.component';
import { FifthPageComponent } from './fifth-page/fifth-page.component';
import { LastPageComponent } from './last-page/last-page.component';
import { SixthPageComponent } from './sixth-page/sixth-page.component';
import { SeventhPageComponent } from './seventh-page/seventh-page.component';
import { EigthPageComponent } from './eigth-page/eigth-page.component';
import { NinthPageComponent } from './ninth-page/ninth-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PopulateDescriptionComponent } from './populate-description/populate-description.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HomePageCarousleComponent } from './home-page-carousle/home-page-carousle.component';
import { AboutUsSecondComponent } from './about-us-second/about-us-second.component';
import { AboutUsThirdComponent } from './about-us-third/about-us-third.component';
import { AboutUsForthComponent } from './about-us-forth/about-us-forth.component';
import { GraniteComponent } from './granite/granite.component';
import { GraniteSecondComponent } from './granite-second/granite-second.component';
import { MarbleComponent } from './marble/marble.component';
import { OthersPageComponent } from './others-page/others-page.component';
import { MonumentsComponent } from './monuments/monuments.component';
import { MarbleSecondComponent } from './marble-second/marble-second.component';
import { QuartziteComponent } from './quartzite/quartzite.component';
import { QuartziteSecondComponent } from './quartzite-second/quartzite-second.component';
import { SlateComponent } from './slate/slate.component';
import { SlateSecondComponent } from './slate-second/slate-second.component';
import { SandstoneComponent } from './sandstone/sandstone.component';
import { SandstoneSecondComponent } from './sandstone-second/sandstone-second.component';
import { LimestoneComponent } from './limestone/limestone.component';
import { LimestoneSecondComponent } from './limestone-second/limestone-second.component';
import { MonumentsSecondComponent } from './monuments-second/monuments-second.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    FirstPageComponent,
    SecondPageComponent,
    ThirdPageComponent,
    ForthPageComponent,
    FifthPageComponent,
    LastPageComponent,
    SixthPageComponent,
    SeventhPageComponent,
    EigthPageComponent,
    NinthPageComponent,
    HomePageComponent,
    PopulateDescriptionComponent,
    AboutUsComponent,
    HomePageCarousleComponent,
    AboutUsSecondComponent,
    AboutUsThirdComponent,
    AboutUsForthComponent,
    GraniteComponent,
    GraniteSecondComponent,
    MarbleComponent,
    OthersPageComponent,
    MonumentsComponent,
    MarbleSecondComponent,
    QuartziteComponent,
    QuartziteSecondComponent,
    SlateComponent,
    SlateSecondComponent,
    SandstoneComponent,
    SandstoneSecondComponent,
    LimestoneComponent,
    LimestoneSecondComponent,
    MonumentsSecondComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    MatGridListModule,
    MatChipsModule,
    MatMenuModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    LottieModule.forRoot({ player: playerFactory }),
    FlexLayoutModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
