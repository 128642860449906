<div style="width: 100%; background: #E2E2E2;">
    <div class="container">

        <div class="row" style="min-height: 5vh;"> </div>
        <div class="row">
            <div class="col-sm-4" (click)="navigateToSandstone_01();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/agra-sand-stone.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>

                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Agra Red Sandstone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            Creamy colors and pinkish tones
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToSandstone_02();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Asian-Gold.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color: #E2E2E2;">
                            Asian Gold Sandstone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: #E2E2E2;">
                            Green grey background with more darker and lighter cloudy pattern
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToSandstone_03();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Autumn-Brown.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText">
                            Autumn Brown Sandstone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText">
                            Has a white background with dapples and veins
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" (click)="navigateToSandstone_04();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Camel-Dust.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <div class="marginLow"></div>
                        <div class="fontForSubText" style="color: black;">
                            Camel Dust Sandstone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color: black;">
                            White / ivory patterns, with gold and brown veins
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToSandstone_05();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Desert-Mint.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:black">
                            Desert Mint Sandstone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Loved for its crisp white bedrock and stunning brown mineral deposits
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" (click)="navigateToSandstone_06();" style="cursor: pointer;">

                <div class="setBackGround" style="background-image: url('/assets/images/Dholpur-Beige.jpg')">
                    <div class="centerAlign">
                        <div class="marginLowLow"></div>
                        <!-- <div class="marginLow"></div> -->
                        <div class="fontForSubText" style="color:black">
                            Dholpur Beige Sandstone
                        </div>
                        <div class="marginLow"></div>
                        <div class="fontForSubSubText" style="color:black">
                            Loved for its crisp white bedrock and stunning brown mineral deposits
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12" style="height: 3vh;">

            </div>
        </div>
    </div>
</div>