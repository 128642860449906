import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'RegalStones';
  step = 0;

  constructor(private router: Router) {
  }

  setStep(index: number) {
    this.step = index;
  }

  navigateToBottom() {
    // window.scrollTo(0,document.body.scrollHeight);
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    console.log("this was called ")
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  navigateToHome() {
    this.router.navigateByUrl("")
    console.log("this was called ")
  }

  navigateToEmployer() {
    this.router.navigateByUrl("employer")
    console.log("this was called ")
  }

  navigateToEmployee() {
    this.router.navigateByUrl("employee")
    console.log("this was called ")
  }

  navigateToJobs() {
    this.router.navigateByUrl("current-jobs")
    console.log("this was called ")
  }

  navigateToIndustries(){
    this.router.navigateByUrl("industries")
    console.log("this was called ")
  }

  navigateToAboutUs(){
    this.router.navigateByUrl("about-us")
    console.log("this was called ")
  }

  navigateToServices(param : string){
    this.router.navigateByUrl('our-services/'+param);
    console.log("this was called ")
  }

  navigateToGranite(){
    this.router.navigateByUrl("granite")
  }

  navigateToMarble(){
    this.router.navigateByUrl("marble")
  }

  navigateToQuart(){
    this.router.navigateByUrl("quartzite")
  }

  navigateToSlate(){
    this.router.navigateByUrl("slate")
  }

  navigateToSandstone(){
    this.router.navigateByUrl("sandstone")
  }

  navigateToLimestone(){
    this.router.navigateByUrl("limestone")
  }

  navigateToOthers(){
    this.router.navigateByUrl("others")
  }

  navigateToMonuments(){
    this.router.navigateByUrl("monuments")
  }
}
