<div class="backgroundStatic">
    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="centerAlign">
                    <img src="/assets/images/logo_vertical.png" style="max-width: 35vh">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="centerAlign">
                    <div class="fontSmallSetBoldSecond">
                        Regal Stones Pvt Ltd.
                    </div>
                    <div class="fontSmallSetBold">
                        1-CH2, Gayatri Nagar, Hiran Magri Sector-5, Udaipur-313001
                    </div>

                    <div class="fontSmallSetBold">
                        Gstin- 08BJGPK0618G1ZF
                    </div>

                    <div class="fontSmallSetBold">
                        E-mail : mazhar@regalstonesindia.com , marketing@regalstonesindia.com
                    </div>

                    <div class="fontSmallSetBold">
                        (AN ISO 9001:2008 REGISTERED COMPANY)
                    </div>
                    <div class="margin"></div>
                    <div>
                        <a class="fa fa-facebook"></a>
                        <a class="fa fa-youtube"></a>
                        <a class="fa fa-instagram"></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="paddingLow"></div>
        <div style="height: 2px; width: 100%; background: grey;"></div>
        <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-8">
                <div class="centerAlign" style="height: 10vh">
                    <div
                        style="display: flex; flex-direction: row; text-align: center; height: 10vh; line-height: 10vh; color: red;">
                        Powered
                        with <i class="fa fa-heart"
                            style="color:red; height: 5px; width: 5px; margin-left: -15px; margin-right: 7px;">
                        </i> by
                        <div style="padding-left: 3px;">
                            <a href="http://www.laaltentech.com" target="_blank">LaalTen Tech</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
            </div>
        </div>
    </div>
</div>