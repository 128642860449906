<div class="container-fluid">

    <div class="row" style="min-height: 5vh;"></div>

    <div class="row">
        <div class="col-sm-12">
            <div class="fontForTitle">
                {{titleDisplay}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6" style="margin-top:2vh">
            <div class="centerAlign">
                <div class="padding"></div>
                <div class="mat-elevation-z8">
                    <img src={{urlDisplay}} style="max-width: 100%;">
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="setBackGround">
                <div class="fontForSubSubText">
                    <div fxShow="true" fxHide.lt-md="true">
                        <div style="min-height: 10vh;"></div>
                    </div>
                    {{contentDisplay}}
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="min-height: 5vh;"></div>

    <div class="row">
        <div class="col-sm-12">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="stoneType">
                    <th mat-header-cell *matHeaderCellDef> More About Our Product </th>
                    <td mat-cell *matCellDef="let element"> {{element.stoneType}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="stoneColor">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> {{element.stoneColor}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>

    <div class="row" style="min-height: 5vh;"></div>

</div>

<app-fifth-page>

</app-fifth-page>

<app-last-page>

</app-last-page>