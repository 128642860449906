<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6">
            <div class="setBackGround">
                <div class="fontForTitle" style="color:rgba(22, 25, 37, 1);">
                    Price
                </div>

                <div class="multiColor">
                    <div class="fontForTitle" style="color:rgba(22, 25, 37, 1);">
                        Service
                    </div>

                    <div class="fontForTitle" style="color:#f0473e; padding-left: 15px;">
                        &
                    </div>
                </div>

                <div class="fontForTitle" style="color:#f0473e;">
                    Quality!
                </div>
                <div class="centerAlign">
                    <div class="padding"></div>
                    <div class="fontForSubSubText" style="color:rgba(22, 25, 37, 1);">
                        Highly competitive prices, impeccable service and international-quality products are
                        simultaneously offered by Regal Stones, thus ensuring that you don't have to compromise on
                        anything.
                    </div>
                    <div class="paddingHigh"></div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">

        </div>
    </div>
</div>