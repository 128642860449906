import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-granite-second',
  templateUrl: './granite-second.component.html',
  styleUrls: ['./granite-second.component.css']
})
export class GraniteSecondComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToGranite_01() {
    this.router.navigateByUrl("/description/granite-01")
    console.log("this was called ")
  }

  navigateToGranite_02() {
    this.router.navigateByUrl("/description/granite-02")
    console.log("this was called ")
  }

  navigateToGranite_03() {
    this.router.navigateByUrl("/description/granite-03")
    console.log("this was called ")
  }

  navigateToGranite_04() {
    this.router.navigateByUrl("/description/granite-04")
    console.log("this was called ")
  }

  navigateToGranite_05() {
    this.router.navigateByUrl("/description/granite-05")
    console.log("this was called ")
  }

  navigateToGranite_06() {
    this.router.navigateByUrl("/description/granite-06")
    console.log("this was called ")
  }

  navigateToGranite_07() {
    this.router.navigateByUrl("/description/granite-07")
    console.log("this was called ")
  }

  navigateToGranite_08() {
    this.router.navigateByUrl("/description/granite-08")
    console.log("this was called ")
  }

  navigateToGranite_09() {
    this.router.navigateByUrl("/description/granite-09")
    console.log("this was called ")
  }

  navigateToGranite_10() {
    this.router.navigateByUrl("/description/granite-10")
    console.log("this was called ")
  }

  navigateToGranite_11() {
    this.router.navigateByUrl("/description/granite-11")
    console.log("this was called ")
  }

  navigateToGranite_13() {
    this.router.navigateByUrl("/description/granite-13")
    console.log("this was called ")
  }

  navigateToGranite_14() {
    this.router.navigateByUrl("/description/granite-14")
    console.log("this was called ")
  }

  navigateToGranite_15() {
    this.router.navigateByUrl("/description/granite-15")
    console.log("this was called ")
  }

  navigateToGranite_16() {
    this.router.navigateByUrl("/description/granite-16")
    console.log("this was called ")
  }

  navigateToGranite_17() {
    this.router.navigateByUrl("/description/granite-17")
    console.log("this was called ")
  }

  navigateToGranite_18() {
    this.router.navigateByUrl("/description/granite-18")
    console.log("this was called ")
  }

  navigateToGranite_12() {
    this.router.navigateByUrl("/description/granite-12")
    console.log("this was called ")
  }

}
