import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';


@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.css']
})
export class FirstPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  options: AnimationOptions = {
    path: '/assets/animations/about-us.json',
  };

  navigateToJobs() {
    this.router.navigateByUrl("current-jobs")
    console.log("this was called ")
  }

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '20wh',
    margin: '0 auto',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

}
