<iframe width="100%" height="100%" id="myVideo" src="https://youtube.com/embed/Cxr4sgc1Kiw?autoplay=1&controls=0&showinfo=0&autohide=1&mute=1"
    frameborder="0">
</iframe>
<div class="setBackGroundBlack">
    <div class="centerAlign">


        <div class="animated fadeInDown delay-2s">
            <div class="multiColor">
                <div class="fontForTitle" style="color:whitesmoke">
                    Beautiful, Today and
                </div>

                <div class="fontForTitle" style="color:#384D48; padding-left: 15px;">
                    Forever
                </div>
            </div>
        </div>

        <div class="padding"></div>
    </div>
</div>