import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us-third',
  templateUrl: './about-us-third.component.html',
  styleUrls: ['./about-us-third.component.css']
})
export class AboutUsThirdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
