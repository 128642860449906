<div class="container-fluid">
    <div class="row" style="min-height: 5vh;"></div>
    <div class="row">
        <div class="col-sm-12">
            <div class="fontForTitle">
                Our Mission
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6" style="margin-top:2vh">
            <div class="centerAlign">
                <div class="padding"></div>
                <img src="/assets/images/background.jpg" style="max-width: 100%;">
            </div>
        </div>

        <div class="col-sm-6">
            <div class="setBackGround">
                <div class="fontForSubSubText">
                    <div fxShow="true" fxHide.lt-md="true">
                        <div style="min-height: 10vh;"></div>
                    </div>
                    We at Regal Stones want to be a process and system driven organization committed to providing high
                    quality Marble, Granite, and ceramic, stone products sourced from around India at the best
                    possible price together with best in class customer service. Our mission is to consistently create
                    value for our precious customers on the principles of respect, responsibility, trust, transparency
                    and teamwork.
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="min-height: 5vh;"></div>
</div>