import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-others-page',
  templateUrl: './others-page.component.html',
  styleUrls: ['./others-page.component.css']
})
export class OthersPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
