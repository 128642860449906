import { Component, OnInit } from '@angular/core';
import {RouterModule, Router} from '@angular/router';

@Component({
  selector: 'app-sandstone-second',
  templateUrl: './sandstone-second.component.html',
  styleUrls: ['./sandstone-second.component.css']
})
export class SandstoneSecondComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToSandstone_01() {
    this.router.navigateByUrl("/description/sandstone-01")
    console.log("this was called ")
  }

  navigateToSandstone_02() {
    this.router.navigateByUrl("/description/sandstone-02")
    console.log("this was called ")
  }

  navigateToSandstone_03() {
    this.router.navigateByUrl("/description/sandstone-03")
    console.log("this was called ")
  }

  navigateToSandstone_04() {
    this.router.navigateByUrl("/description/sandstone-04")
    console.log("this was called ")
  }

  navigateToSandstone_05() {
    this.router.navigateByUrl("/description/sandstone-05")
    console.log("this was called ")
  }

  navigateToSandstone_06() {
    this.router.navigateByUrl("/description/sandstone-06")
    console.log("this was called ")
  }

}
