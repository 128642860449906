<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6">
            <div class="setBackGround">
                <div class="fontForTitle" style="color:whitesmoke;">
                    Captivating
                </div>

                <div class="multiColor">
                    <div class="fontForTitle" style="color:whitesmoke;">
                        Style
                    </div>

                    <div class="fontForTitle" style="color:#f0473e; padding-left: 15px;">
                        in Every
                    </div>
                </div>

                <div class="fontForTitle" style="color:#f0473e;">
                    Stone !
                </div>
                <div class="centerAlign">
                    <div class="padding"></div>
                    <div class="fontForSubSubText" style="color:whitesmoke">
                        Every stone at Regal Stones is quality tested with proper delivery options that creates one of a
                        kind styled floors.
                    </div>
                    <div class="paddingHigh"></div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">

        </div>
    </div>
</div>