import { Component, OnInit } from '@angular/core';
declare var Flickity: any;

@Component({
  selector: 'app-third-page',
  templateUrl: './third-page.component.html',
  styleUrls: ['./third-page.component.css']
})
export class ThirdPageComponent implements OnInit {

  constructor() {}

  ngOnInit() {  }

}
