<div style="background: #6E7271; padding-bottom: 8vh; border-radius: 10px; margin: 2.5%">
    <div class="centerAlign">
        <div class="margin"></div>
        <div class="multiColor">
            <div class="fontForTitle" style="color:#161925">
                Stone
            </div>

            <div class="fontForTitle" style="color:whitesmoke; padding-left: 15px;">
                Elegance
            </div>
        </div>
        <div class="marginHigh"></div>
    </div>

    <!-- Flickity HTML init -->
    <div style="background: rgba(22, 25, 37, 0);">
        <div class="carousel" data-flickity='{ "imagesLoaded": true, "percentPosition": false }'>
            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/granite_flooring_2.jpg" alt="orange tree" style="margin-right: 10px; max-height: 300px;"/>
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Granite</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">Flooring</div>
            </div>

            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/granite_flooring_3.jpg" alt="submerged" style="margin-right: 10px; max-height: 300px;" />
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Granite</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">Flooring</div>
            </div>

            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/granite_flooring_4.jpg" alt="look-out" style="margin-right: 10px; max-height: 300px;" />
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Brown Granite</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">Flooring</div>
            </div>

            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/granite_flooring.jpg" alt="One World Trade" style="margin-right: 10px; max-height: 300px;" />
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Staircases</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">& Floors</div>
            </div>

            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/kitchen_table_top_2.jpg" alt="drizzle" style="margin-right: 10px; max-height: 300px;" />
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Kitchen</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">Floors</div>
            </div>

            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/kitchen_table_top.jpg" alt="cat nose" style="margin-right: 10px; max-height: 300px;" />
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Kitchen</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">Table Tops</div>
            </div>

            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/marble_steps.jpg" alt="cat nose" style="margin-right: 10px; max-height: 300px;" />
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Staircase</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">Natural Stones</div>
            </div>

            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/marble-staircase.jpg" alt="cat nose" style="margin-right: 10px; max-height: 300px;" />
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Marble</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">Staircases</div>
            </div>

            <div style="height: 350px; width:300px;  margin-right: 10px;">
                <img src="/assets/images/carousle-items/vanity_stone.jpg" alt="cat nose" style="margin-right: 10px; max-height: 300px;" />
                <div class="fontForCarousel" style="width:300px; color:whitesmoke; padding-top: 10px;">Vanity</div>
                <div class="fontForCarousel" style="width:300px; color:#1b1b1b; padding-top: 10px;">Stones</div>
            </div>
        </div>
    </div>
</div>
