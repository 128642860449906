import { Component, OnInit } from '@angular/core';
import{Router} from '@angular/router';

@Component({
  selector: 'app-marble-second',
  templateUrl: './marble-second.component.html',
  styleUrls: ['./marble-second.component.css']
})
export class MarbleSecondComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToMarble_01() {
    this.router.navigateByUrl("/description/marble-01")
    console.log("this was called ")
  }

  navigateToMarble_02() {
    this.router.navigateByUrl("/description/marble-02")
    console.log("this was called ")
  }

  navigateToMarble_03() {
    this.router.navigateByUrl("/description/marble-03")
    console.log("this was called ")
  }

  navigateToMarble_04() {
    this.router.navigateByUrl("/description/marble-04")
    console.log("this was called ")
  }

  navigateToMarble_05() {
    this.router.navigateByUrl("/description/marble-05")
    console.log("this was called ")
  }

}
