<div>
    <mat-toolbar>
        <mat-toolbar-row class="alignToolBarComponent">

            <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
                <mat-icon>menu</mat-icon>
            </button>

            <span class="center" (click)="navigateToHome()">
                <span style="cursor: pointer;">
                    <img src="/assets/images/logo_horizontal.png" style="max-height: 13vh;">
                </span>
            </span>

            <div style="width: 20vw;"></div>

            <div fxShow="true" fxHide.lt-md="true">

                <a mat-button (click)="navigateToHome()">
                    <mat-icon>home</mat-icon>
                    Home
                </a>

                <a mat-button (click)="navigateToAboutUs()">
                    <mat-icon>account_box</mat-icon>
                    About Us
                </a>

                <a mat-button (click)="navigateToGranite()">
                    <mat-icon>view_quilt</mat-icon>
                    Granite
                </a>

                <a mat-button (click)="navigateToMarble()">
                    <mat-icon>view_quilt</mat-icon>
                    Marble
                </a>

                <a mat-button (click)="navigateToSandstone()">
                    <mat-icon>view_quilt</mat-icon>
                    Sandstone
                </a>

                <a mat-button [matMenuTriggerFor]="others">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    Others
                </a>

                <mat-menu #others="matMenu">
                    <button mat-menu-item (click)="navigateToQuart()">Quartzite</button>
                    <button mat-menu-item (click)="navigateToLimestone()">Limestone</button>
                    <button mat-menu-item (click)="navigateToMonuments()">Monuments</button>
                    <button mat-menu-item (click)="navigateToSlate()">Slate</button>
                </mat-menu>

                <a mat-button (click)="navigateToBottom()">
                    <mat-icon>contact_mail</mat-icon>
                    Contact Us
                </a>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #sidenav fixedInViewport="true">
            <div>
                <span class="center" style="padding-top: 4vh;">
                    <img src="/assets/images/logo_vertical.png" style="width: 80vw;">
                </span>
                <mat-accordion class="example-headers-align">
                    <mat-action-list>
                        <button mat-list-item (click)="navigateToAboutUs(); sidenav.toggle();"> About Us </button>
                        <button mat-list-item (click)="navigateToBottom(); sidenav.toggle();"> Contact Us </button>
                        <button mat-list-item (click)="navigateToGranite(); sidenav.toggle();"> Granite </button>
                        <button mat-list-item (click)="navigateToMarble(); sidenav.toggle();"> Marble </button>
                        <button mat-list-item (click)="navigateToSandstone(); sidenav.toggle();"> Sandstone </button>

                    </mat-action-list>
                    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Others
                            </mat-panel-title>
                            <mat-panel-description>
                                <div style="margin-right: 100px;"></div>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-action-list>
                            <button mat-list-item (click)="navigateToQuart(); sidenav.toggle();"> Quartzite </button>
                            <button mat-list-item (click)="navigateToLimestone(); sidenav.toggle();">Limestone</button>
                            <button mat-list-item (click)="navigateToMonuments(); sidenav.toggle();">Monuments</button>
                            <button mat-list-item (click)="navigateToSlate(); sidenav.toggle();">Slate</button>

                        </mat-action-list>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>

            <router-outlet>

            </router-outlet>

        </mat-sidenav-content>
    </mat-sidenav-container>
</div>