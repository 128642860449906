<div class="container-fluid">
    <div class="row" style="min-height: 5vh;"></div>
    <div class="row">
        <div class="col-sm-12">
            <div class="fontForTitle">
                Our Journey
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6" style="margin-top:2vh">
            <div class="centerAlign">
                <div class="padding"></div>
                <img src="/assets/images/granite.jpg" style="max-width: 100%;">
            </div>
        </div>

        <div class="col-sm-6">
            <div class="setBackGround">
                <div class="fontForSubSubText">
                    <div fxShow="true" fxHide.lt-md="true">
                        <div style="min-height: 10vh;"></div>
                    </div>
                    Our journey started in 2001 as merchant exporters, where we soon gained expertise in the stone
                    industry. Over the years Regal Stones has been able to excel in the field of natural stones by ensuring high
                    standards of selection in block procurement; developing strategic alliances with quarries;
                    harbouring relationships with factories in close proximity to quarries; having a relentless focus on
                    quality and a customer centric approach.
                </div>

                <div class="fontForSubSubText">
                    <div fxShow="true" fxHide.lt-md="true">
                        <div style="min-height: 10vh;"></div>
                    </div>
                    With multiple factory locations, several own quarries, strong quarry alliances, offices in all major
                    granite cities of India and large warehouses, we at Regal Stones - have established ourselves as one of the
                    most reliable and highly client-centric supplier. This has given us an un-parallel edge to source,
                    process & supply granite from India.
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="min-height: 5vh;"></div>
</div>